// Main page of chapter
import { useState, useEffect } from "react"; 

const BtcDataPg = () => { 
  const [btcData, setBtcData] = useState({}); 
  
  useEffect(() => { 
    fetch(`https://api.coindesk.com/v1/bpi/currentprice.json`) 
      .then((response) => response.json()) 
      .then((jsonData) => setBtcData(jsonData.bpi.USD)) 
      .catch((error) => console.log(error)); 
  }, []); 
 
  const updateBtc = () => {
    fetch(`https://api.coindesk.com/v1/bpi/currentprice.json`) 
        .then((response) => response.json()) 
        .then((jsonData) => {
            setBtcData(jsonData.bpi.USD);
            console.log(jsonData);
        })
        .catch((error) => console.log(error)); 
    console.log('update BTC info call');
  }

  return ( Object.keys(btcData).length > 0 ? (
      <div> 
        <h1>Current BTC/USD data</h1> 
        <p>Code: {btcData.code}</p> 
        <p>Symbol: &#36; {btcData.symbol}</p> 
        <p>Rate: {btcData.rate}</p> 
        <p>Description: {btcData.description}</p> 
        <p>Rate Float: {btcData.rate_float}</p> 
        <button id="update" onClick={updateBtc}>Update Btc info</button>
      </div>
    ) : (
      <h1>Data loading...</h1>
    )     
  ); 
} 

export default BtcDataPg;