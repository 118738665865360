/*****************************************
Stanislav S. Polivtsev
February 2023
version 1.2

Indicator component for graph indicate state
of process 
******************************************/
import Meter from "./Meter";

const Indicator = (props) => {
  /* Key points: 0 R 0.5 Y 0.85 G 1.1 Y 1.3 R 1.5*/
  const pMax = Math.floor(props.target*1.5);
  var pLow = Math.floor(props.target*0.5); 
  var pHigh = Math.floor(props.target*0.85);
  if (props.value > props.target) {
    pLow = Math.floor(props.target*1.1); 
    pHigh = Math.floor(props.target*1.3);
  }
  
  return (
    <div style={{alignSelf: 'center', minWidth: '3em', width: '90%',}}>
      <Meter id={props.id} min="0" max={pMax}
        low={pLow} high={pHigh} optimum={props.target}
        title={props.title ? props.title : `${props.value}/${props.target}`} 
        width='45%' value={props.value}>
        {props.children} </Meter>
    </div>
  )
}

export default Indicator;