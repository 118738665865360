/*****************************************
Stanislav S. Polivtsev
start:    21 March 2023
current:  28 March 2023

Map page
ver.1.2.2 Notification, ScreenLock, useEffect, memo 
******************************************/
import { useState, useEffect, memo } from "react";
import dingAudio from './assets/sound/ding.mp3';
import EventLog from "./components/EventLog";

const dingSingle = new Audio(dingAudio);
const dingSinglePlay = () => {
  dingSinglePlay.volume=0.02;
  dingSingle.play();
};

const aLat = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}°${quotient2}'${quotient3.toFixed(1)}"${angle>=0?'N':'S'}`  
}

const aLng = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}°${quotient2}'${quotient3.toFixed(1)}"${angle>=0?'E':'W'}`  
}

const aLatU = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}%C2%B0${quotient2}'${quotient3.toFixed(1)}%22${angle>=0?'N':'S'}`  
}

const aLngU = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}%C2%B0${quotient2}'${quotient3.toFixed(1)}%22${angle>=0?'E':'W'}`  
}

const pstToStr = (pst) => {
  if (!pst) {return 'Undefined position'}
  let lStr = `${pst.timeStr} - `; 
  lStr += `Latitude: ${pst.latitude} °, Longitude: ${pst.longitude} °`;
  lStr += (pst.altitude) ? `, Altitude: ${pst.altitude.toFixed(1)} m` : '';
  lStr += (pst.accuracy) ? `, Accuracy: ${pst.accuracy.toFixed(1)} m` : '';
  lStr += (pst.altitudeAccuracy) ? `, Altitude accuracy: ${pst.altitudeAccuracy.toFixed(1)} m` : '';
  lStr += (pst.heading) ? `, Heading: ${pst.heading} °` : '';
  lStr += (pst.speed) ? `, Speed: ${pst.speed} m/s` : '';
  return lStr
};

const pstToStrDN = (pst) => {
  // Convert position object to string lat, lng in Decimal Notation
  return `${pst.latitude.toFixed(6)},${pst.longitude.toFixed(6)}`
}

const pstToStrHN = (pst) => {
  // Convert position object to string lat, lng in Historical Notation
  return `Lat: ${aLat(pst.latitude)} Lng: ${aLng(pst.longitude)}`
}

const pstToStrHNP = (pst) => {
  // Convert position object to string lat, lng in Historical Notation
  return `${aLat(pst.latitude)}+${aLng(pst.longitude)}`
}

const pstToGMapS = (pst) => {
  // Convert position object to string for gmap search based on Historical Notation
  return `${aLatU(pst.latitude)}+${aLngU(pst.longitude)}+(My%20location)`
}



//50%C2%B026'46.8%22N+30%C2%B035'55.5%22E+(My%20location)

const pstToGMapLink = (pst) => {
  return `https://www.google.com/maps/place/${pstToStrHNP(pst)}/@${pstToStrDN(pst)},18z?output=embed`
}

const PosGoogleMapView =  memo(({pst}) => {
  useEffect(() =>console.log(`Render PosGoogleMapView component, pst=${pst ? pstToStrHN(pst) : "null"}`));
  
  return (
    <div style={{width: "100%"}}>
      <iframe title="GoogleMap" style={{width:"100%", height:"40vh", frameBorder:"0", margin:"0"}} 
        src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${pstToGMapS(pst)}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
        loading="lazy">
        <a href="https://www.maps.ie/distance-area-calculator.html">measure area maps</a>
      </iframe>
    </div>
  )
}, (({pst: oldPst}, {pst: newPst})=>{
  // Rendering optimization:  if coordinates difference small 
  // do not rerender Google Map
  let small = (Math.abs(oldPst.latitude-newPst.latitude)<0.00003) 
    && (Math.abs(oldPst.longitude-newPst.longitude)<0.00003);
  // console.log('Delta latitude: ', oldPst.latitude-newPst.latitude);
  // console.log('Delta longitude: ',oldPst.longitude-newPst.longitude);
  console.log("Coordinate difference are small: ", small);
  return small;
}))
/*
worked link for embed Google Map 
https://maps.google.com/maps?width=100%25&height=600&hl=en&q=50%C2%B026'46.8%22N+30%C2%B035'55.5%22E+(My%20location)&t=&z=14&ie=UTF8&iwloc=B&output=embed
*/


const PosMap = memo(({pst,}) => {
  useEffect(() =>console.log(`Render PosMap component, pst=${pst ? pstToStrHN(pst) : "null"}`));
  if (pst) {
    return (
      <div>
        <div>{pstToStr(pst)}</div>
        <PosGoogleMapView pst={pst}/>
      </div>
    )
  } else {
    return <div>Select position</div>
  }  
})


const RoutePg = () => {
  // String array for diagnostic information, show on test page
  const [pstLog, setPstLog] = useState([]);
  const [testLog, setTestLog] = useState([]);
  const [trackerActive, setTrackerActive] = useState(false);
  const [highAccuracyGeo, setHighAccuracyGeo] = useState(true);
  const [curPstInd, setCurPstInd] = useState(); 
  const navigator = window.navigator;
  const [tInterval, setTInterval] = useState(60);
  
  const toLog = (textStr) => {
    // Create TimeStamp for log record
    const key1 = Date.now();
    const c = new Date(key1);
    const logStr = `${c.toLocaleTimeString("de-DE")}: ${textStr}`
    const rKey = Math.floor(Math.random()*10000)+textStr.slice(0,5)+textStr.slice(-10,-1); 
    setTestLog(tl => [{key: key1+'-'+rKey, strL: logStr}, ...tl]);
  }

  const PositionLog = ({pstArray}) => {
    return (
      <div>  
        <h3>Geo positions Log <em>{(pstLog.length>=1) ? 'from '+pstLog[pstLog.length-1].timeStr : ''}</em></h3>
        <div style={{fontSize:"0.9em",}}>
          
          {
            pstArray.map((logObj, pstIndex) => 
            <div key={logObj.timestamp} 
              onClick={(e)=>{setCurPstInd(pstIndex)}}>
              { `${pstIndex}: ${logObj.timeStr} - ${pstToStrHN(logObj)} `}
              <a href={pstToGMapLink(logObj)} target="_blank" rel="noreferrer"><em>Open</em></a>
            </div>)
          }
        </div>
      </div>
    )      
  }

  /* ***** Timer event handler ***** */
  useEffect(() => {
    const toPstLog = (pst) => {
      // Save Position object pst to the pstLog
      setPstLog(tl => [...tl, pst]);
    }
  
    const getLocation = () => {
      // Define geolocation callback functions
      function success(position) {
        // Create new position structure uses IIEF - Immediately Invoked Function Expression
        const pst = (
          ({coords:{latitude, longitude, altitude, accuracy, altitudeAccuracy, 
            heading, speed}, timestamp})=>
          ({latitude, longitude, altitude, accuracy, altitudeAccuracy, 
            heading, speed, timestamp,
            timeStr: new Date(timestamp).toLocaleTimeString("de-DE")})
        )(position); 
        toPstLog(pst);
        toLog(pstToStr(pst));
      }
    
      function error() {
        //status.textContent = "Unable to retrieve your location";
        toLog("Unable to retrieve your location");
      }
    
      const options = {
        enableHighAccuracy: highAccuracyGeo,
        maximumAge: 5000,
        timeout: 5000,
      };
      
      if (!navigator.geolocation) {
        // status.textContent = "Geolocation is not supported by your browser";
        toLog("Geolocation is not supported by your browser");
      } else {
        // status.textContent = "Locating…";
        const watchID = navigator.geolocation.getCurrentPosition(success, error, options);
      }
    }
    
    let interval = null;
    if (trackerActive) {
      interval = setInterval(() => {
        getLocation();
        dingSinglePlay();
        }, tInterval*1000);
      console.log(`Activate timer with interval ${tInterval} sec`);
      toLog(`Activate timer with interval ${tInterval} sec`);
      getLocation();
    } else {
      console.log(`Timer inactive, timer interval ${tInterval} sec`);
      toLog(`Timer inactive, timer interval ${tInterval} sec`);
    }
    
    return () => {
      console.log('Clear current timer function');
      toLog('Clear current timer function');
      if (interval) {clearInterval(interval);toLog('Clear time interval function');};
    }
  }, [trackerActive, tInterval, highAccuracyGeo, navigator.geolocation]);
  
  return (
    <div style={{maxWidth:'1200px', textAlign:'left',margin:'0 2em',}}>
      <h1>Route page</h1>
      <PosMap pst={curPstInd!==undefined && pstLog ? pstLog[curPstInd]: null}></PosMap>
      <div className="Field">
        <button onClick={(e)=>{setTrackerActive(c=>!c);}}
          >{(trackerActive) ? 'Stop tracker':'Start tracker'}
        </button>  
        <label htmlFor="timeout">Tracker interval</label>
        <select
          style={{ margin:"0.5em", padding:"0.5em", borderRadius:"1em",}} 
          value={tInterval} id="timeout"
          onChange={(e) => setTInterval(e.target.value)}>
          <option value="20">20s</option>
          <option value="60">60s</option>
          <option value="120">2min</option>
          <option value="300">5min</option>
        </select>
        <button onClick={(e)=>{setHighAccuracyGeo(c=>!c)}}
          >{(highAccuracyGeo) ? 'High Accuracy':'Low Accuracy'}
        </button>  
        <button onClick={(e)=>{setPstLog([]);setCurPstInd(undefined)}} >Clear log</button>
      </div>
      <PositionLog pstArray={pstLog} />
      <EventLog evArray={testLog} />
    </div>
  );
}

export default RoutePg;

/* Debug purpose only elements!! Compare structures in the memory 
<div>{`evArray ${typeof(evArray)}, ${evArray.length} `+JSON.stringify(evArray).slice(0,100)}</div>
<div>{`testLog ${typeof(testLog)}, ${testLog.length} `+JSON.stringify(testLog).slice(0,100)}</div>
*/

/* 
const Check1 = memo(({id}) => {
  useEffect(() => console.log('Render Check1 id=', id, ));
  return (
      <div>
        <div>Check1 - {id}</div>
      </div>
  )  
});
*/