import { UserProvider, useUser, HD1 } from "./UserContext";

const LoggedInUser = () => {
  const {user} = useUser();
  return (
  <p>
    Hello <span className="Username">{user.name}</span>
  </p>
  );
};

const Header = () => {
  return (
    <header>
      <h2>Blog App</h2>
      <LoggedInUser />
    </header>

  );
};

const Page = () => {
  const {user} = useUser();
  return (
  <div>
    <h2>Sharks int the ocean</h2>
    <p>
    Sharks are a group of elasmobranch fish characterized by a 
    cartilaginous skeleton, five to seven gill slits on the sides 
    of the head, and pectoral fins that are not fused to the head. 
    Modern sharks are classified within the clade Selachimorpha 
    (or Selachii) and are the sister group to the rays. However, 
    the term "shark" has also been used to refer to all extinct 
    members of Chondrichthyes with a shark-like morphology, such 
    as hybodonts and xenacanths.

    The oldest modern sharks are known from the Early Jurassic. 
    They range in size from the small dwarf lanternshark 
    (Etmopterus perryi), a deep sea species that is only 17 
    centimetres (6.7 in) in length, to the whale shark (Rhincodon 
    typus), the largest fish in the world, which reaches 
    approximately 12 metres (40 ft) in length.[1] Sharks are found 
    in all seas and are common to depths up to 2,000 metres 
    (6,600 ft). They generally do not live in freshwater, 
    although there are a few known exceptions, such as the bull 
    shark and the river shark, which can be found in both seawater 
    and freshwater.[2] Sharks have a covering of dermal denticles 
    that protects their skin from damage and parasites in addition 
    to improving their fluid dynamics. They have numerous sets of 
    replaceable teeth.
    </p>
    <p>Written by {user.name}</p>
  </div>
  )
}

function Blog() {
  return (
    <div>
      <Header />
      <Page />
    </div>
  )
}

function Context1Pg() {
  return (
    <div>
      <UserProvider>
        <Blog />
      </UserProvider>
      <HD1>
        <div>Hello</div>
      </HD1>
    </div>
  );
};

export default Context1Pg;