import { useState } from "react";
import React from "react";
import { redirect } from "react-router-dom";

const Button = ({ children, backgroundColor}) => {
  return <button style={{ backgroundColor }}>{children}</button>
};

const Alert = ({ children }) => {
  return (
    <>
      <div className="Overlay">
      <div className="Alert">{children}</div>
      </div>
    </>
  );
}

const DeleteButton = () => {
  return <Button backgroundColor="red">Delete</Button>
}

const Row = ({children, spacing}) => {
  const childStyle = {
    marginLeft: `${spacing}px`,
  };
  /* Use map and cloneElement for styling 
  all paragraph convert to inline,
  add spacing for all element over 1 and color red for 5th element
  */
  return (
    <div className="Row">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          style: {
            ...child.props.style,
            ...(index > 0 ? childStyle : {}),
            ...(index === 5 ? {color: "red",} : {}),
          }
        });
      })};
    </div>
  );
};

const LiveOrders = () => {
  return (
    <div>
      <Row spacing={16}>
        <p>Pizza Margarita</p>
        <p>2</p>
        <p>30$</p>
        <p>18:30</p>
        <p>John</p>
        <p>fast line</p>
      </Row>
      <Row spacing={16}>
        <p>Pizza Olive</p>
        <p>1</p>
        <p>20$</p>
        <p>19:00</p>
        <p>Anna</p>
        <p>usual line</p>
      </Row>
    </div>
  );
};

const DialogPg = () => {
  return (
    <div className="App">
      <header>Little Lemon Restaurant</header>
      <Alert>
        <h4>Current orders:</h4>
        <LiveOrders />
        <h4>Delete Account</h4>
        <p>
          Are you sure you want to proceed? 
          You will miss all your delicious recipes! 
        </p>
        <div>{null}</div> 
        <DeleteButton />
      </Alert>
    </div>
  );
}


export default DialogPg;