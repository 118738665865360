const EventList = ({evArray}) => {
  return (
    <div style={{fontSize:"0.5em",fontStyle:"italic",}}>
      {evArray.map(logArray => <div key={logArray.key}>{logArray.strL}</div>)}
    </div>
  )
}

const EventLog = ({evArray}) => {
  return (
    <div>
      <h4>Event Log:</h4>
      <div style={{height:"5em", border:"inset 2px", padding:"0.1em 0.5em", overflowY:"scroll"}}>
        <EventList evArray={evArray} />
      </div>  
    </div>
  )
}

export default EventLog;