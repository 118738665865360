/*****************************************
Stanislav S. Polivtsev
start: 10 March 2023

Testing page
ver.1.2 Notification, ScreenLock, useEffect 
******************************************/
import { useState, useEffect } from "react";
import dingAudio from './assets/sound/ding.mp3'

const dingSingle = new Audio(dingAudio);
const dingSinglePlay = () => {
  dingSinglePlay.volume=0.1;
  dingSingle.play();
};

const TestPg = () => {
  // String array for diagnostic information, show on test page
  const [testText, setTestText] = useState([]);
  const [testLog, setTestLog] = useState([]);
  
  const navigator = window.navigator;
  const [wakeLock, setWakeLock] = useState(null);
  const [wakeLock2, setWakeLock2] = useState(null);
  
  const [testBackground, setTestBackground] = useState(60);
    

  const screenLock = async () => {
    var text = [];
    // Create a reference for the Wake Lock.
    let textContent;
    
    // create an async function to request a wake lock
    try {
      var wakeLock1 = await navigator.wakeLock.request("screen");
      setWakeLock(wakeLock1);
      textContent = "Wake Lock is active!";
    } catch (err) {
      // The Wake Lock request has failed - usually system related, such as battery.
      textContent = `${err.name}, ${err.message}`;
    }
    text.push(textContent);
    text.push(`Wake Lock: ${wakeLock1}`);
    // Assign test result to the screen element
    setTestText(text);
  }
  
  const screenUnlock = async () => {
    var text = [];
    // Create a reference for the Wake Lock.
    let textContent;
    
    if (wakeLock) {
      wakeLock.release().then(() => {
        setWakeLock(null);
      });
      textContent = "Wake Lock is released";
    } else {
      textContent = "Wake Lock is not locked!!  Tou are first need to lock";
    }
    text.push(textContent);
    text.push(`Wake Lock: ${wakeLock}`)
    // Assign test result to the screen element
    setTestText(text);
  }

  const testScreenLock = () => {
    const isScreenLockSupported = () => {
      return ('wakeLock' in navigator);
    }
    var text = [];
    // Create test
    text.push(`Screen Lock support: ${isScreenLockSupported()}`);
    if (wakeLock) {
      text.push(`Wake Lock: ${wakeLock} (released-${wakeLock.released}, type-${wakeLock.type})`);
    } else {
      text.push('Screen not locked');
    };  
    
    // Assign test result to the screen element
    setTestText(text);
  }

  const systemLock = async () => {
    var text = [];
    // Create a reference for the Wake Lock.
    let textContent;
    let wakeLock2m = null;
    
    // create an async function to request a wake lock
    try {
      wakeLock2m = await navigator.wakeLock.request("system");
      setWakeLock2(wakeLock2m);
      textContent = "Wake System Lock is active!";
    } catch (err) {
      // The Wake Lock request has failed - usually system related, such as battery.
      textContent = `${err.name}, ${err.message}`;
    }
    text.push(textContent);
    text.push(`Wake Lock: ${wakeLock2m}`);
    //text.push(`System lock (released-${wakeLock2m.released}, type-${wakeLock2m.type})`)
    // Assign test result to the screen element
    setTestText(text);
  }


  const testAudio = () => {
    if ('mediaCapabilities' in navigator) {
      const audioFileConfiguration = {
        type : 'file',
        audio : {
            contentType: "audio/mp3",
            channels: 2,
            bitrate: 132700,
            samplerate: 5200
        }
      };
    
      navigator.mediaCapabilities.decodingInfo(audioFileConfiguration).then((result) => {
        var text=[];
        console.log(`Configuration`,audioFileConfiguration.audio, `is ${result.supported ? '' : 'not '}supported,`);
        console.log(`${result.smooth ? '' : 'not '}smooth, and`);
        console.log(`${result.powerEfficient ? '' : 'not '}power efficient.`);
        text.push(`Configuration: `+JSON.stringify(audioFileConfiguration.audio));
        text.push(`is ${result.supported ? '' : 'not '}supported, `
          + `${result.smooth ? '' : 'not '}smooth, `
          + `and ${result.powerEfficient ? '' : 'not '}power efficient.`);
        setTestText(text);
        })
        .catch(() => {
          //console.log(`decodingInfo error: ${contentType}`)
        });
    }
  }
  
  const testNavigator = () => {
    var text = [];
    // Create test
    text.push('cookieEnabled: '+navigator.cookieEnabled);
    text.push('deviceMemory: '+navigator.deviceMemory+' GB');
    text.push('hardwareConcurrency: '+navigator.hardwareConcurrency);
    text.push('Language: '+navigator.language);
    text.push('Languages: '+navigator.languages);
    text.push('onLine: '+navigator.onLine);
    text.push('pdfViewerEnabled: '+navigator.pdfViewerEnabled);
    text.push('userAgent: '+navigator.userAgent);
    text.push('userAgentData: '+JSON.stringify(navigator.userAgentData));
    text.push('webdriver: '+JSON.stringify(navigator.webdriver));  
    // Test notification. Check browser support of the notifications
    text.push("* Notifications:")
    if (!("Notification" in window)) {
      text.push("This browser does not support desktop notification");
    } else {
      text.push("This browser support desktop notification");
      text.push(`Notification permission: ${Notification.permission}`);
    };
    // Assign test result to the screen element
    setTestText(text);
  }

  const toLog = (textStr) => {
    // Create TimeStamp for log record
    const key1 = Date.now();
    const c = new Date(key1);
    const logStr = `${c.toLocaleTimeString("de-DE")}: ${textStr}`
    const rKey = Math.floor(Math.random()*10000)+textStr.slice(0,10)+textStr.slice(-10,-1); 
    setTestLog(tl => [{key: key1+'-'+rKey, strL: logStr}, ...tl]);
  };

  /* ***** Timer event handler ***** */
  useEffect(() => {
    const interval = setInterval(() => {
      const wl = wakeLock;
      const lWakeLock = (wl!==null && !(wl.released));
      const logStr = `Wake lock = ${lWakeLock};` 
      console.log(logStr);
      toLog(logStr);
      dingSinglePlay();
    }, testBackground*1000);
    
    console.log(`Setup new interval function ${testBackground} sec`);
    toLog(`Setup new interval function ${testBackground} sec`);

    return () => {
      console.log('Clear current timer function');
      toLog('Clear current timer function');
      clearInterval(interval);
    }
  }, [testBackground, wakeLock]);
  

  function notifyMe() {
    // Notification data
    let newMessage = ['New web message', 
        { body: 'Visit our site with Pomodoro technique',
          tag: 'check1',
        }
    ];
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification(newMessage[0], newMessage[1]);
      notification.onclick = function(event) {
        event.preventDefault(); // prevent the browser from focusing the Notification's tab
        window.open('http://localhost:3000/pomodoro', '_blank');
      }
      /* // Check thi snippet, notification work without it
      navigator.serviceWorker.ready.then(function(serviceWorker) {
        serviceWorker.showNotification(newMessage[0], newMessage[1]);});
      */
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification("Hi there!");
          // …
        }
      });
    }
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }
  

  return (
    <div style={{maxWidth:'1200px', textAlign:'left',margin:'0 2em',}}>
      <h1>Test page</h1>
      <h2>Navigator test result:</h2>
      <div>
        {testText.map(txt => <div key={txt.slice(0,30)}>{txt}</div>)}
      </div>
      
      <h2>Test section</h2>
      <button onClick={testNavigator}>Navigator</button>
      <button onClick={testAudio}>Audio</button>
      <button onClick={notifyMe}>Notify Me</button>
      <div className="Field">
        <button onClick={testScreenLock}>Test Screen Lock</button>
        <button onClick={screenLock}>Lock Screen</button>
        <button onClick={screenUnlock}>Unlock Screen</button>
        <button onClick={systemLock}>Test System Lock</button>
        <label htmlFor="timeout">Timeout</label>
        <input 
          type="number"
          id="timeout"
          style={{display: "inline-block", margin:"0.5em", width:"5em",}} 
          placeholder="Timeout"
          min="10" step="10" 
          name="timeout" value={testBackground}
          onChange= {(e) =>{let val = e.target.value>=10 ? e.target.value: 10;
            setTestBackground(val); 
            console.log('timeout=', val);}} 
        />
      </div>
      <h3>Log:</h3>
      <div style={{fontSize:"0.7em",fontStyle:"italic",}}>
        {testLog.map(logArray => <div key={logArray.key}>{logArray.strL}</div>)}
      </div>
      
    </div>
  );
}

export default TestPg;