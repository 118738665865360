import { useState } from "react";

const data = [
  {
    id: "1",
    title: "Tiramisu",
    description: "The best tiramisu in the town",
    price: "5.00",
  },
  {
    id: "2",
    title: "Lemon Ice Cream",
    description: "Mind blowing taste",
    price: "4.20",
  },
  {
    id: "3",
    title: "Chocolate mousse",
    description: "Unexplored flavour",
    price: "6.00",
  },

];

const LItem = props => (
  <tr>
    <td>
      <label>{props.id}</label>
    </td>
    <td>
      <label>{props.title}</label>
    </td>
    <td>
      <input/>
    </td>
    <td>
      <label>{props.price}</label>
    </td>
  </tr>
);


const SortListPg = () => {
  
  const [itemsList, setItemsList] = useState(data);
  /* Filter */
  // const sortList = props.data.filter(item => item.calories < 500);
  /* Sort button functions for items list */
  const priceUpOrder = () => {
    setItemsList([...itemsList].sort((a,b) => {return (a.price-b.price)}))
  };
  const priceDownOrder = () => {
    setItemsList([...itemsList].sort((a,b) => {return (b.price-a.price)}))
  };
  const priceUpId = () => {
    setItemsList([...itemsList].sort((a,b) => {return (a.id-b.id)}))
  };
  const priceDownId = () => {
    setItemsList([...itemsList].sort((a,b) => {return (b.id-a.id)}))
  };
  const reverseOrder = () => {
    setItemsList([...itemsList].reverse())
  };

  return (
    <div>
      <h1>Sorted Items List</h1>
      <button onClick={priceUpId} >Up #</button>
      <button onClick={priceDownId} >Down #</button>
      <button onClick={priceUpOrder} >UpPrice</button>
      <button onClick={priceDownOrder} >DownPrice</button>
      <button onClick={reverseOrder} >Reverse</button>
      <table>
        <thead>
          <tr>
            <td>#</td>
            <td>Name</td>
            <td>Note</td>
            <td>Price</td>
          </tr>
        </thead>
        <tbody>
          {itemsList.map((item, index) => (
            <LItem key={item.id} id={item.id} title={item.title} price={item.price} />
          ))}
        </tbody>
      </table>
    </div>
  )    
}

export default SortListPg;