import React, {useState} from "react";

const ModeToggler = () => {
    const [stateDarkMode, setDarkMode] = useState(false);
    const lightMode = <h2>Light Mode is On</h2>;
    const darkMode = <h2>Dark Mode is On</h2>;
    const toggleMode = () => {
        setDarkMode(!stateDarkMode);
        console.log('State: ', stateDarkMode? 'Dark Mode' : 'Light Mode');
    };
    
    return(
        <div>
            {stateDarkMode? darkMode : lightMode}
            <button onClick={toggleMode}>
                Toggle
            </button>
        </div>
    )
}

export default ModeToggler;