const data = [
  {
    id: "1",
    title: "Tiramisu",
    description: "The best tiramisu in the town",
    price: "$5.00",
  },
  {
    id: "2",
    title: "Lemon Ice Cream",
    description: "Mind blowing taste",
    price: "$4.20",
  },
  {
    id: "3",
    title: "Chocolate mousse",
    description: "Unexplored flavour",
    price: "$6.00",
  },

];

const topDeserts = data.map((dessert, index) => {
  return {
    content: `#${index}: ${dessert.title} - ${dessert.description}`,
    price: dessert.price,
  }
});

const a1 = [1, 4, 7, 10, 12];
const asq = a1.map(x=>x**2);

console.log(topDeserts);
console.log(a1,asq)

const ListItemsPg = () => {
  // Prepare list of elements
  const topDeserts = data.map((dessert, index) => {
    // Format text for element
    const itemText = `#${index}: ${dessert.title} - ${dessert.price}`; 
    return <li key={itemText}> {itemText} </li>;
  })
  return (
    <div>
      <h1>List Items</h1>
      <ul>
        {topDeserts}
      </ul>
    </div>
  )    
}

export default ListItemsPg;