const Btn = (props) => {
    // Handler for onClick event
    const clickHandler = () => console.log("Click button");
    // Handler for mouseOver event for current element
    const mouseOverHandler = () => console.log("Mouse over button");
    return(
        <button onClick={clickHandler} onMouseOver={mouseOverHandler}>
            Click me
        </button>
    )
}

export default Btn;