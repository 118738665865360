import './App.css';
import Heading from './components/Heading'
import Logo2 from "./components/Logo2"
import RegisterForm from './components/RegisterForm';

function PersonalPg(props) {
  console.log('App props:', props);
  return (
    <div className="App">
      <article>
        <Heading title="Personal information" />
        <Logo2 title="University of North Texas" />
        <RegisterForm />
      </article>
    </div>
  );
}

export default PersonalPg;

