import { useState } from 'react'; 

const RegisterForm = () => { 
  const [form, setForm] = useState({ 
    firstName: 'Jon', 
    lastName: 'East', 
    email: 'Jon_East@google.com', 
  }); 

  return ( 
    <> 
      <div style={{ display: 'flex', flexDirection: 'column', }}>
      <label> 
        First name: 
        <input 
          value={form.firstName} 
          onChange={e => { 
            setForm({ 
              ...form, 
              firstName: e.target.value 
            }); 
          }} 
        /> 
      </label> 
      <label> 
        Last name: 
        <input 
          value={form.lastName} 
          onChange={e => { 
            setForm({ 
              ...form, 
              lastName: e.target.value 
            }); 
          }} 
        /> 
      </label> 
      <label> 
        Email: 
        <input 
          value={form.email} 
          onChange={e => { 
            setForm({ 
              ...form, 
              email: e.target.value 
            }); 
          }} 
        /> 
      </label>
      </div> 
      <p> 
        {form.firstName}{' '} 
        {form.lastName}{' '} 
        ({form.email})
      </p> 
    </> 
  ); 
}

export default RegisterForm;