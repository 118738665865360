import './App.css';
import Logo2 from "./components/Logo2"
import { useState, useEffect } from 'react';

const zeroPad = (num, places) => String(num).padStart(places, '0')

const TimerDisplay = (props) => {
  const tSec = props.timer % 60;
  const tMin = (props.timer/60)>>0;
  const style1 = {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: 'black',
    color: 'white',
    padding: '0.3em',
    fontSize: '1.5em',
    border: 'outset 2px orange',
    borderRadius: '0.3em',
    margin: '0.1em'
  }
  const style2 = {
    position: 'absolute',
    top: '-0.05em', left: '0.4em',
    display: 'inline-block',
    color: 'orange',
    fontSize: '0.4em',
  }

  return (
    <div style={style1}>
      <div style={style2}>
        {props.title ? props.title : 'Timer'}
      </div>
      <div>
        {zeroPad(tMin,2)+':'+zeroPad(tSec,2)}
      </div>
    </div>
  );
}

const TimerBlock = (props) => {
  // Local state for timer
  const [timer1, setTimer1] = useState(10);
  
  // Work with timer in useEffect
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer1 > 0) {
        setTimer1(timer1-1);
        console.log(`Call timer. timer=${timer1}`)
        //setTimer1(timer)
      } 
    }, 1000);
    return () => {
      console.log('Call return func useEffect. Clear current timer');
      clearInterval(interval);
    }
  }, [timer1]);

  console.log('Call Timer Block, props:', props);

  return (
    <div>
      <h3>Timer block</h3>
      <div><TimerDisplay title='Countdown' timer={timer1} /></div>
      <button onClick={() => setTimer1(5)}>5</button>
      <button onClick={() => setTimer1(50)}>50</button>
      <button onClick={() => setTimer1(150)}>150</button>
    </div>
  )
}

const TimerPg = (props) => {
  console.log('Run timer page props:', props);
  return (
    <div className="App">
      <article>
        <Logo2 title="University of North Texas" />
        <TimerBlock />
      </article>
    </div>
  );
}

export default TimerPg;

