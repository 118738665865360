/*****************************************
Stanislav S. Polivtsev
February 2023

Meter component for graph indicate state 
******************************************/
const Meter = (props) => {
  return (
    <div>
      <label htmlFor={props.id}>{props.children}</label>
      <meter id={props.id} 
        style={{width: (props.width ? props.width : '40%'), height:'1.5em', verticalAlign:'-0.35em', margin:'0 0.6em' }}
        min={props.min ? props.min : "0"} 
        max={props.max ? props.max : "100"}
        low={props.low ? props.low : "30"} 
        high={props.high ? props.high : "80"}
        optimum={props.optimum ? props.optimum : "60"}
        title={props.title}
        value={props.value}>
      </meter>
    </div>
  )
}

export default Meter;