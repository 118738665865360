import { useState, useRef, useEffect, useLayoutEffect } from "react";
import './assets/css/vplr.css';
// Interface element images dynamic (png) and buttons (svg)
import speaker0 from './assets/images/speaker0.png';
import speaker1 from './assets/images/speaker1.png';
import speaker2 from './assets/images/speaker2.png';
import speaker3 from './assets/images/speaker3.png';
import btPlay from './assets/images/btplay.svg';
import btPause from './assets/images/btpause.svg';
import btFwd15 from './assets/images/btfwd15.svg';
import btFwd60 from './assets/images/btfwd60.svg';
import btRw15 from './assets/images/btrw15.svg';
import btRw60 from './assets/images/btrw60.svg';
//import btStop from './assets/images/btstop.svg';
import btSpeed from './assets/images/btspeed.svg';
import btStart from './assets/images/btstart.svg';
import btEnd from './assets/images/btend.svg';


const zeroPad = (num, places) => String(num).padStart(places, '0')

const ts2str = (ts) => {
  const tSec = Math.floor(ts % 60);;
  const tMin = Math.floor(ts/60);
  return zeroPad(tMin,2)+':'+zeroPad(tSec,2);
}

const SoundIndicator = (props) => {
  var imgSrc=null;
  if (props.muted) {
    imgSrc = speaker0; 
  } else if (props.volume<6) {
    imgSrc = speaker1; 
  } else if (props.volume<14) {
    imgSrc = speaker2; 
  } else {
    imgSrc = speaker3; 
  }
  return (
    <span>
      <img src={imgSrc} alt='speaker' 
        style={{width:'1.8em', height:'1.8em', verticalAlign:'middle',}} />
    </span>
  ); 
}


const EnPlayer = (props) => {
  /* Use hook useRef for direct access video element properties and methods 
  send link to this reference in target element when in the render, like: 
  <video ref={evpRef} ... */
  // Video Container Reference
  const evcRef = useRef(null);
  // Video Player Reference
  const evpRef = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  
  const [mediaCurrentPosition, setMediaCurrentPosition] = useState(0);
  const [mediaDuration, setMediaDuration] = useState(0);
 
  const [muted, setMuted] = useState((props.muted)? true: false);
  const [volume, setVolume] = useState("20");
  const [vRate, setVRate] = useState("1");
  
  useLayoutEffect(() => {
    setWidth(evcRef.current.offsetWidth);
    setHeight(evcRef.current.offsetHeight);
  }, []);
 
  // Add eventListener to resize event
  useEffect(() => {
    function handleWindowResize() {
      /* Set synchronization of the window parameters with internal hook value
      on element change size */
      setWidth(evcRef.current.clientWidth);
      setHeight(evcRef.current.clientHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Add eventListener to timeupdate event
  useEffect(() => {
    const hTimeUpdate = () => {
      /* Set synchronization of the window parameters with internal hook value
      on media change current position (periodical event) */
      setMediaCurrentPosition(evpRef.current.currentTime)
    }

    // Add listener to the specific video element
    const playerElm = evpRef.current;
    if (playerElm) playerElm.addEventListener('timeupdate', hTimeUpdate);
    console.log('Set timeupdate listener')

    return () => {
      playerElm.removeEventListener('timeupdate', hTimeUpdate);
      if (playerElm) console.log('Remove timeupdate listener')
    };
  }, []);
  
  // Add eventListener to keypress event
  useEffect(() => {
    const hKeyDown = (evt) => {
      console.log('Pressed key:', evt.altKey? 'Alt+':'', evt.shiftKey?'Shift+':'',
        evt.ctrlKey? 'Ctrl+':'', evt.keyCode, evt.code);
      switch (evt.keyCode) {
        case 32:
          console.log('Play / pause handle', evt.keyCode);
          play();
          evt.preventDefault();  
          break;
        case 37:
          if (evt.shiftKey) {
            rewindBack60();
          } else {
            rewindBack();
          }
          break;
        case 39:
          if (evt.shiftKey) {
            rewindForward60();
          } else {
            rewindForward();
          }
          break;
        case 38:
          if(evpRef.current.volume<=0.95) {
            evpRef.current.volume+=0.05;
          } else evpRef.current.volume=1;
          evt.preventDefault();  
          break;
        case 40:
          if(evpRef.current.volume>=0.05) {
            evpRef.current.volume-=0.05;
          } else evpRef.current.volume=0;
          evt.preventDefault();  
          break;
        case 13:
          toggleFullScreen();
          evt.preventDefault();  
          break;
        default:
          console.log('No handle for handle key: ', evt.code);
      };
    }
    
    const playerElm = evpRef.current;
    if (!playerElm) return;
    window.addEventListener('keydown', hKeyDown);  
    console.log('Set keyboard keydown listener to the: ', playerElm);

    return () => {
      window.removeEventListener('keydown', hKeyDown);
      console.log('Remove EnPlayer keyboard key listener');
    };
  }, []);
  /* 
  document.addEventListener("keydown", (e) => {
  if (e.keyCode === 13) {
    toggleFullScreen();
  }
}, false);
  */

  // Add eventListener to durationchange event
  useEffect(() => {
    const hDurationChange = () => {
      setMediaDuration(evpRef.current.duration)
    }
    const playerElm = evpRef.current;
    if (playerElm) playerElm.addEventListener('durationchange', hDurationChange);  
    return () => {
      if (playerElm) playerElm.removeEventListener('durationchange', hDurationChange);
    };
  }, []);

  // Add eventListener to volumechange event
  useEffect(() => {
    const hVolumeChange = () => {
      const newVolume = Math.round(evpRef.current.volume*20);
      if (volume!==newVolume) setVolume(newVolume);
    }
    const playerElm = evpRef.current;
    if (playerElm) playerElm.addEventListener('volumechange', hVolumeChange);  
    return () => {
      if (playerElm) playerElm.removeEventListener('volumechange', hVolumeChange);
    };
  }, [volume,]);

  useEffect(() => {
    // Add eventListener to ratechange event
    const hRateChange = () => {
      setVRate(evpRef.current.playbackRate)
    }
    const playerElm = evpRef.current;
    if (playerElm) playerElm.addEventListener('ratechange', hRateChange);  
    return () => {
      if (playerElm) playerElm.removeEventListener('ratechange', hRateChange);
    };
  }, []);

  useEffect(() => {
    // Add eventListener to fullscreenchange event
    const hFullscreenChange = () => {
      if (document.fullscreenElement===evcRef.current) {
        setFullScreen(true); 
      } else {
        setFullScreen(false)
      };
    };

    window.addEventListener('fullscreenchange', hFullscreenChange);  
    
    return () => {
      window.removeEventListener('fullscreenchange', hFullscreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      // If the document is not in full screen mode
      // make the video full screen
      evcRef.current.requestFullscreen();
    } else {
      // Otherwise exit the full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const play = () => {
    if (evpRef.current) {
      evpRef.current.paused ?  evpRef.current.play() : evpRef.current.pause();
    };
  };
  const goToStart = () => evpRef.current.currentTime=0;
  const middle = () => evpRef.current.currentTime=evpRef.current.duration/2;
  const goToEnd = () => evpRef.current.currentTime=evpRef.current.duration-5;
  const rewindBack = () => evpRef.current.currentTime = evpRef.current.currentTime-15;
  const rewindForward = () => evpRef.current.currentTime = evpRef.current.currentTime+15;
  const rewindBack60 = () => evpRef.current.currentTime = evpRef.current.currentTime-60;
  const rewindForward60 = () => evpRef.current.currentTime = evpRef.current.currentTime+60;
      
  return (
    <div id='vContainer' ref={evcRef}>
      <video id='EnhVPlayer' ref={evpRef} src={props.src} muted={muted}></video>
      <div id='monitor'>Monitor info {width}x{height}</div>
      <div id='cPanel'>
        <div id='cPanelLabel'>
          <div><img src={btPlay} alt='Play'/></div>
          cPanel
        </div>
        <div id='cVPanel'>
          <button onClick={goToStart} title='Go to the start'>
            <img src={btStart} alt='Start'/></button>
          <button onClick={rewindBack60} title='Rewind back 60 sec'>
            <img src={btRw60} alt='Rewind 60'/></button>
          <button onClick={rewindBack} title='Rewind back 15 sec'>
            <img src={btRw15} alt='Rewind 15'/></button>
          {evpRef.current && <button onClick={play} title={(evpRef.current.paused) ? 'Play' : 'Pause'}>
            {evpRef.current.paused ? <img src={btPlay} alt='Play'/> : <img src={btPause} alt='Pause'/>}</button>}
          <button onClick={middle} title='Go to the middle'>Mid</button>
          <button onClick={rewindForward} title='Rewind forward 15 sec'>
            <img src={btFwd15} alt='Forward 15'/></button>
          <button onClick={rewindForward60} title='Rewind forward 60 sec'>
            <img src={btFwd60} alt='Forward 60'/></button>
          <button onClick={goToEnd} title='Go to the end'>
            <img src={btEnd} alt='End'/></button>
          <span> {ts2str(mediaCurrentPosition)}</span>
          <span> / {ts2str(mediaDuration)} </span>
          <button onClick={toggleFullScreen} 
            title={fullScreen ? 'Exit full screen mode (Esc)' : 'Enter full screen mode' }>
            {fullScreen ? '\u29c8' : '\u2922' }</button>
          <span>
            <div className='dr-mn-main' 
              onClick={(e) => {
                if (muted) {
                  setMuted(false);
                  evpRef.current.muted=false;
                } else {
                  setMuted(true);
                  evpRef.current.muted=true;
                };
              }}>
              <SoundIndicator volume={volume} muted={muted}/>
            </div>
            <div className='dr-mn-main' >
              <label> {zeroPad(volume,2)}</label>
              <div className='dr-mn'>
                <input type="range" id="volume" orient="vertical"
                  style={{display:'inline-block',}} 
                  value={volume} min="0" max="20"   
                  onChange={(e) => {
                    let vol=e.target.value/20;
                    evpRef.current.volume=vol;
                  }}/>
              </div>
            </div>
            <div className='dr-mn-main'>
              <label htmlFor="rate"><img src={btSpeed} alt='Speed'/> {(Math.floor(vRate*10)/10).toFixed(1)}</label>
              <div className='dr-mn'>  
                <input type="range" id="rate" orient="vertical"
                  style={{display:'inline-block',}} 
                  value={vRate} min="0.4" max="2" step="0.1" list="rateMarkers" 
                  onChange={(e) => {
                    let rate=e.target.value;
                    evpRef.current.playbackRate=rate;
                  }}/>
              </div>
              <datalist id="rateMarkers">
                <option value="0.8"></option>
                <option value="1.0"></option>
                <option value="1.2"></option>
              </datalist>
            </div>
          </span>
          <div>
            <input type="range" id="currentPosition" title='Current position in media'
              value={mediaCurrentPosition} min="0" max={mediaDuration} step="1" list="timeMarkers"
              onChange={(e) => {
                evpRef.current.currentTime=e.target.value;
                setMediaCurrentPosition(e.target.value);
              }}/>
            <datalist id="timeMarkers">
              <option value="0"></option>
              <option value="60"></option>
              <option value="120"></option>
              <option value="180"></option>
              <option value="240"></option>
              <option value="360"></option>
              <option value="480"></option>
              <option value="600"></option>
              <option value="1200"></option>
              <option value="2400"></option>
              <option value="3600"></option>
              <option value="4800"></option>
              <option value="7200"></option>
              <option value="9000"></option>
            </datalist>
          </div>
          
        </div>
      </div>
      
    </div>  
  );
};

export default EnPlayer;