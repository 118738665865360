import './App.css';
import logo from './logo.svg';
import GlobalNav from './GlobalNav';
import { Outlet } from "react-router-dom";

function App(props) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" style={{width:'3em', height:'3em'}} />
        <div>{props.title}</div>
      </header> 
      <GlobalNav />
      <Outlet />
    </div>
  );
}

export default App;