import { useState, } from "react";
import video1 from './assets/video/advanced_hooks1.mp4';
import EnPlayer from "./components/EnPlayer";


const VideoPlPg = () => {
  // Local state for text import - name
  const [name, setName] = useState("");
  // Local state for scoreinput 
  const [score, setScore] = useState("2");
  // Form submit handler
  const handleSubmit = (e) => {
    // Prevent default behavior on form submission
    e.preventDefault();
    console.log(`Form submitted! name=${name};`);
    setName("");
  };

  return (
    <div style={{maxWidth:'1200px',margin:'auto',}}>
      <h1>Video player with form</h1>
      <EnPlayer src={video1} />    
      <form onSubmit={handleSubmit}>
        <h2>Form group</h2>
        <fieldset>
          <div className="Field">
            <label htmlFor="name">Name</label>
            <input type="text"
              id="name"
              placeholder="Name"
              name="name"
              value={name}
              onChange= {(e) =>{setName(e.target.value); 
                console.log('name=', e.target.value);}} 
            />
          </div>
          <div>
            <label htmlFor="score">Score {score}</label>
            <input type="range" id="score" 
              value={score} min="0" max="10" 
              onChange={(e) => setScore(e.target.value)}/>
          </div>
          <button disabled={!name} type="submit">Submit</button>
          <button type="button">New</button>
          <button type="reset">Reset</button>
        </fieldset>
      </form>
    </div>
  );
}

export default VideoPlPg;