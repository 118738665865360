import logo from "./assets/images/logo.png";

const ContactPg = () => {
    return(
        <div>
            <h1>Contact</h1>
            <img src={logo} height={50} alt="Logo" />
            <p>This page content contact information</p>
        </div>
        
    );
};

export default ContactPg;