const zeroPad = (num, places) => String(num).padStart(places, '0')

const TimerDisplay = (props) => {  
  const t = Math.abs(props.timer);
  const t_over = (props.timer<0);
  const bkgrColor = props.background ? props.background : 'black';
    
  const tSec = t % 60;
  const tMin = Math.floor(t/60);
  const style1 = {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: (t_over ? 'darkred' : bkgrColor),
    color: 'white',
    padding: '0.3em',
    fontSize: '1.5em',
    border: 'outset 2px orange',
    borderRadius: '0.3em',
    margin: '0.1em'
  }
  const style2 = {
    position: 'absolute',
    top: '-0.05em', left: '0.4em',
    display: 'inline-block',
    color: 'orange',
    fontSize: '0.4em',
  }

  return (
    <div style={style1}>
      <div style={style2}>
        {props.title ? props.title : 'Timer'}
      </div>
      <div>
        {zeroPad(tMin,2)+':'+zeroPad(tSec,2)}
      </div>
    </div>
  );
}

export default TimerDisplay;