import { Link } from "react-router-dom";

const GlobalNav = () => {
  return(
    <nav>
        <Link to="/" className="nav-item">Homepage</Link>
        <Link to="route" className="nav-item">Route</Link>
        <Link to="pomodoro" className="nav-item">Pomodoro</Link>
        <Link to="video_pl" className="nav-item">Video</Link>
        <Link to="personal" className="nav-item">Personal info</Link>
        <Link to="birdsong" className="nav-item">Bird song</Link>
        <Link to="calc" className="nav-item">Calculator</Link>
        <Link to="contact" className="nav-item">Contact</Link>
        <Link to="list" className="nav-item">List items</Link>
        <Link to="s_list" className="nav-item">Sort list</Link>
        <Link to="form1" className="nav-item">Form1</Link>
        <Link to="context1" className="nav-item">Context1</Link>
        <Link to="gift_card" className="nav-item">Gift card</Link>
        <Link to="btcdata" className="nav-item">Btc data</Link>
        <Link to="dialog" className="nav-item">Dialog</Link>
        <Link to="mouse_rend" className="nav-item">Mouse Render</Link>
        <Link to="timer" className="nav-item">Timer</Link>
        <Link to="test" className="nav-item">Test</Link>
        <Link to="rendertest" className="nav-item">RenderTest</Link>
      </nav>
  )
};

export default GlobalNav