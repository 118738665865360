import { useState } from "react";

const Form1Pg = () => {
  // Local state for text import - name
  const [name, setName] = useState("");
  // Local state for scoreinput 
  const [score, setScore] = useState("2");
  // Form submit handler
  const handleSubmit = (e) => {
    // Prevent default behavior on form submission
    e.preventDefault();
    console.log(`Form submitted! name=${name};`);
    setName("");
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="Field">
            <label htmlFor="name">Name</label>
            <input type="text"
              id="name"
              placeholder="Name"
              name="name"
              value={name}
              onChange= {(e) =>{setName(e.target.value); 
                console.log('name=', e.target.value);}} 
            />
          </div>
          <div>
            <label htmlFor="score">Score {score}</label>
            <input type="range" id="score" 
              value={score} min="0" max="10" 
              onChange={(e) => setScore(e.target.value)}/>
          </div>
          <button disabled={!name} type="submit">Submit</button>
          <button type="button">New</button>
          <button type="reset">Reset</button>
        </fieldset>
      </form>
    </div>
  );
}

export default Form1Pg;