/*****************************************
Stanislav S. Polivtsev
start: 09 February 2023

Pomodoro technic page
ver.1.4.5 with Theme, Indicators and Cycle list 
******************************************/

import './App.css';
import './assets/css/pomodoro.css';
/* import Logo2 from "./components/Logo2" */
import { useState, useEffect } from 'react';
import TimerDisplay from './components/TimerDisplay';
import Indicator from './components/Indicator';
// Load audio sources
import dingAudio from './assets/sound/ding.mp3'
import dingIdeaAudio from './assets/sound/ding-idea.mp3'
import bellSingleAudio from './assets/sound/Bell-Single2.mp3'
import seaWavesBirdsAudio from './assets/sound/sea-waves-birds2.mp3'
import birdSquawkAudio from './assets/sound/bird-squawk.mp3'
import imgWork1 from './assets/images/work1.svg'
import imgRelax from './assets/images/relax1.svg'
import imgOther from './assets/images/other1.svg'


// Create audio sources. Local resources must be loaded in import
const audioDing = new Audio(dingAudio);
const audioDingIdea = new Audio(dingIdeaAudio);
const bellSingle = new Audio(bellSingleAudio);
const bell2Single = new Audio(bellSingleAudio);
const seaWavesBird = new Audio(seaWavesBirdsAudio);
const bS = new Audio(birdSquawkAudio);

/* Helper functions */
const sec = (msec) => {return Math.floor(msec/1000)};
const mint = (msec) => {return Math.floor(msec/60000)};
const mins = (sec) => {return Math.floor(sec/60)};
// Random range functions
const getRnd = (min, max) => {
  return (Math.random() * (max - min) ) + min;
}
const getRndInt = (min, max) => {
  return Math.floor(getRnd(min, max));
}

const TimerBlock = (props) => {  
  const dingPlay = () => {
    audioDing.play();
  };

  const bellSinglePlay = () => {
    bellSingle.play();
  };

  const bellDoublePlay = () => {
    // For playing several overlapping sound - create several audio elements
    // Call via on play end event - useful for check playing end of the record
    // bellSingle.addEventListener('ended', ()=>{console.log('Callback on ended');bellSingle.play();}, {once: true});
    bellSingle.play();
    setTimeout(()=>{bell2Single.play()}, 360)
  };

  const seaBirdMixPlay = () => {
    seaWavesBird.volume=0.2;
    seaWavesBird.play();
    setTimeout(()=>{
      bS.volume=getRnd(0.1,0.3); 
      if (getRndInt(0,2)) {bS.play();console.log('bird1')};
      setTimeout(()=>{
        bS.volume=getRnd(0.1,0.3); 
        if (getRndInt(0,2)) {bS.play();console.log('bird2')};
        setTimeout(()=>{
          bS.volume=getRnd(0.05,0.15); 
          if (getRndInt(0,2)) {bS.play();console.log('bird3')};
        }, getRndInt(2500,3500))
      }, getRndInt(2500,3500))
    }, getRndInt(500,2800))
  };

  const seaBirdBellSinglePlay = () => {
    seaBirdMixPlay();
    setTimeout(()=>{bellSinglePlay();}, 2000);
  };
  const seaBirdBellDoublePlay = () => {
    seaBirdMixPlay();
    setTimeout(()=>{bellDoublePlay();}, 2000);
  };

  function dingIdeaPlay() {
      audioDingIdea.play();
  };
  
  const stFlexTableH = {
    display: 'flex',
    flexDirection: 'row',
  };
  const stFlexTableV = {
    display: 'flex',
    flexDirection: 'column',
  };
  /* The styles of the page elements */
  const stFlexChildV = {
    flexGrow: '1',
    flexBasis: '5em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    /*justifyContent: 'center',*/
    padding: '0.2em',
    borderRadius: '0.2em',
    margin: '0.2em 0.2em 0.2em',
    transition: 'all ease-in 0.4s',
  }

  const stFlexChildM = {
    ...stFlexChildV,
    flexGrow: '1.4',
    flexBasis: '7em',
  };

  const stFlexChildML = {
    ...stFlexChildM,
    alignItems: 'left',
  };

  // Local state for text import - name
  const [workTitle, setWorkTitle] = useState("");
  
  // Local state for mode and timers
  const [workLog, setWorkLog] = useState([]);
  
  const [curCycle, setCurCycle] = useState({
    work: 25*60,
    relax: 5*60,
    snd: {
      work: [
        {t: 23*60, snd: dingPlay },
        {t: 25*60, snd: seaBirdBellSinglePlay },
        {t: 28*60, snd: seaBirdBellDoublePlay },
      ],
      relax: [
        {t: 270, snd: dingPlay },
        {t: 300, snd: bellSinglePlay },
        {t: 420, snd: bellDoublePlay },
      ],
      other: [
        {t: 270, snd: dingPlay },
        {t: 300, snd: bellSinglePlay },
        {t: 600, snd: bellDoublePlay },
      ]
    }
  });
  
  const initCurState = {
    start_t: Date.now(),    // Time of the cycle start
    mode: 'work',
    prev_mode: 'relax',
    // Time of the current mode, in this cycle, start
    prev_t: Date.now(),     
    work: 0,    // work time, msec
    relax: 0,
    other: 0,
    snd: {
      work:0,
      relax:0,
      other:0,
    }
  }

  const [curState, setCurState] = useState(initCurState);

  /* Change current work mode of application */
  const setMode = (newMode) => {
    const startNewCycle = () => {
      // Save current state to the log list
      // Create log object, time of cycle in seconds
      const dateNow = Date.now(); 
      const pMode = curState.mode;
      var logItem = {
        start_t: curState.start_t,
        title: workTitle,
        work: {cur: sec(curState.work), trj: curCycle.work},
        relax: {cur: sec(curState.relax), trj: curCycle.relax},
        other: {cur: sec(curState.other), trj: curCycle.relax},
      }
      logItem[pMode].cur += sec(dateNow - curState.prev_t);
      setWorkLog([logItem, ...workLog]);
      // Init new cycle
      setCurState({...initCurState, start_t: dateNow, prev_t: dateNow});
    }
    
    // Usual mode change routine
    const pMode = curState.mode;
    const dateNow = Date.now(); 
    if (newMode === curState.mode) return;
    
    if ((newMode === 'work') && (curState.mode === 'relax' || 
      (curState.mode === 'other' && curState.prev_mode === 'relax'))) {
        startNewCycle();
    } else {
      setCurState({...curState, mode: newMode, prev_t: dateNow, prev_mode: pMode, 
        [pMode]: curState[pMode] + dateNow - curState.prev_t});
    };
  }

  // Work with timer callback in background with useEffect 
  useEffect(() => {
    const interval = setInterval(() => {
      const pMode = curState.mode;
      const dateNow = Date.now();
      setCurState({...curState, prev_t: dateNow, 
        [pMode]: curState[pMode] + dateNow - curState.prev_t});
      // console.log('Call timer. curState:',curState)
      
      console.log(`Mode: ${pMode} - ${sec(curState[pMode])}s`);
      
      if (curCycle.snd[pMode][curState.snd[pMode]] &&
          (curState[pMode] > curCycle.snd[pMode][curState.snd[pMode]].t*1000)) {
        console.log(`(${pMode}) play sound #${curState.snd[pMode]}`);
        curCycle.snd[pMode][curState.snd[pMode]].snd();
        curState.snd[pMode] += 1;
      }
      
    }, 2000);
    
    console.log('Setup new interval function');

    return () => {
      console.log('Call return func useEffect. Clear current timer function');
      clearInterval(interval);
    }
  }, [curState, curCycle]);

  const LItem = props => (
    <div style={stFlexTableH}>  
      <div style={stFlexChildV}>
        {props.date.toLocaleTimeString('de-DE')}
      </div>
      <div style={stFlexChildML}>
        <div style={{ textAlign:'left',}}>
          {props.item.title}
        </div>
      </div>
      <div style={stFlexChildV}>
        <Indicator value={props.item.work.cur} target={props.item.work.trj}>
          {mins(props.item.work.cur)}/{mins(props.item.work.trj)}
        </Indicator>
      </div>
      <div style={stFlexChildV}>
        <Indicator value={props.item.relax.cur} target={props.item.relax.trj}>
            {mins(props.item.relax.cur)}/{mins(props.item.relax.trj)}
        </Indicator>
      </div>
      <div style={stFlexChildV}>
        <Indicator value={props.item.other.cur} target={props.item.other.trj}>
            {mins(props.item.other.cur)}/{mins(props.item.other.trj)}
        </Indicator>
      </div>
    </div>
  );
  
  const ShowWorkLog = (props) => {
    return (
      <div>
        <h2>Work Log:</h2>
        <article>
          <div style={{...stFlexTableV}}>  
            <div style={stFlexTableH}>
              <div style={stFlexChildV}>Time</div>
              <div style={stFlexChildM}>Title</div>
              <div style={stFlexChildV}>Work</div>
              <div style={stFlexChildV}>Relax</div>
              <div style={stFlexChildV}>Other</div>
            </div>
            {workLog.map((item, index) => (
              <LItem key={item.start_t} index={index} date={new Date(item.start_t)} item={item} />
            ))}
          </div>
        </article>    
      </div>
    );
  }
  
  const Panel = (props) => {
    return (
      <div className={props.className}>
        <div class="demo-bg7 flex-vc">
          <div class="pop-unroll">
            <img src={props.img} alt="" />
          </div>
        </div>
        <div className="pan-content flex-vc">
          {props.children}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2>Timer block</h2>
      <button style={{width:'20em',}}
        onClick={() => setMode('work')}>Start</button>
      <div className="Field">
        <label htmlFor="title">Title</label>
        <input type="text" style={{display:'inline-block', margin:'0.2em 1em',}}
          id="title"
          placeholder="Title of the work"
          name="title"
          value={workTitle}
          onChange= {(e) =>{setWorkTitle(e.target.value); console.log(`title=${e.target.value}`)} } 
        />
      </div>

      <div className="stFrame stFlexTableH">
        <div id="idWork" onClick={() => setMode('work')}
            className={curState.mode==='work' ? "panel a-pan" : "panel"}>
          <div class="demo-bg7 flex-vc">
            <div class="pop-unroll">
              <img src={imgWork1} alt="" draggable="false"/>
            </div>
          </div>
          <div className="pan-content flex-vc">
            <h3 >Work</h3> 
            <Indicator value={sec(curState.work)} target={curCycle.work}
              title={`${mint(curState.work)}/${Math.floor(curCycle.work/60)}`} />
            <TimerDisplay title='Countdown' background="darkblue" 
              timer={curCycle.work-sec(curState.work)} />
            <TimerDisplay timer={sec(curState.work)} />
          </div>
        </div>
        
        <div id="idRelax" onClick={() => setMode('relax')}
            className={curState.mode==='relax' ? "panel a-pan" : "panel"}>
          <div class="demo-bg7 flex-vc">
            <div class="pop-unroll">
              <img src={imgRelax} alt="" draggable="false"/>
            </div>
          </div>
          <div className="pan-content flex-vc">
            <h3>Relax</h3>
            <Indicator value={sec(curState.relax)} target={curCycle.relax}
              title={`${mint(curState.relax)}/${Math.floor(curCycle.relax/60)}`} />
            <TimerDisplay title='Countdown' background="darkblue" 
              timer={curCycle.relax-Math.floor(curState.relax/1000)} />
            <TimerDisplay timer={Math.floor(curState.relax/1000)} />
          </div>
        </div>

        <div id="idOther" onClick={() => setMode('other')}
            className={curState.mode==='other' ? "panel a-pan" : "panel"}>
          <div class="demo-bg7 flex-vc">
            <div class="pop-unroll">
              <img src={imgOther} alt="" draggable="false"/>
            </div>
          </div>
          <div className="pan-content flex-vc">
            <h3>Other</h3>
            <Indicator value={sec(curState.other)} target={curCycle.relax}
              title={`${mint(curState.other)}/${Math.floor(curCycle.relax/60)}`} />
            <TimerDisplay timer={Math.floor(curState.other/1000)} />
          </div>
        </div>           
      </div>
      <ShowWorkLog />
      <h4>Test sound</h4>
      <button onClick={() => dingPlay()}>Ding test</button>
      <button onClick={() => bellSinglePlay()}>Single Bell</button>
      <button onClick={() => bellDoublePlay()}>Double Bell</button>
      <button onClick={() => dingIdeaPlay()}>Ding Idea</button>
      <button onClick={() => seaBirdMixPlay()}>Sea Birds</button>
      <button onClick={() => seaBirdBellSinglePlay()}>Sea Birds Bell</button>
      <button onClick={() => seaBirdBellDoublePlay()}>Sea Birds Double Bell</button>
    </div>
  )
}

const PomodoroPg = (props) => {
  console.log('Run Pomodoro page, props:', props);
  return (
    <div className="App">
      <article>
        <h1>Effective work technique - Pomodoro</h1>
        <TimerBlock />
      </article>
    </div>
  );
}

export default PomodoroPg;