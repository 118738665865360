import Heading from './components/Heading'
import Logo2 from "./components/Logo2"
import Btn from "./components/Btn"
import ModeToggler from './components/ModeToggler';
import { Link } from 'react-router-dom';

function Homepage(props) {
  return (
    <div className='Hrz-table'>
      <div>
        <article>
          <Heading title='Hi' />
          <Logo2 title="University of North Texas" />
          <Btn />
          <ModeToggler />
        </article>
      </div>
      <div style={{textAlign:'left',}}>
        <h3>Main pages</h3>
        <ul>
        <li><Link to="form1" className="nav-item">Form1</Link></li>
        <li><Link to="calc" className="nav-item">Calculator</Link></li>
        <li><Link to="gift_card" className="nav-item">Gift card</Link></li>
        <li><Link to="btcdata" className="nav-item">Btc data</Link></li>
        <li><Link to="dialog" className="nav-item">Dialog</Link></li>
        <li><Link to="mouse_rend" className="nav-item">Mouse Render</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Homepage;