import avatar from "../assets/images/nt_university.jpg"

const Logo2 = (props) => {
    const userPicture = (
    <div>
        <img src={avatar} alt="avatar" />
        <h2>{props.title}</h2>
    </div>    
    )
    return userPicture;
}

export default Logo2;