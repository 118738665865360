import './App.css';
import Logo2 from "./components/Logo2"
import {useEffect, useState, memo} from "react";

const Check1 = ({id, l}) => {
  useEffect(() => console.log(`Render Check1 id=${id}, lvl=${l}`));
  return (
      <div>
        <div>Check1 - {id}</div>
      </div>
  )  
}

const Check2 = memo(({id, l}) => {
  useEffect(() => console.log(`Render Check2 id=${id}, lvl=${l}`));
  return (
      <div>
        <div>Check2m - {id} lvl:{l}</div>
      </div>
  )  
} /*, (oldProps, newProps)=>{return true}*/);

const Check3 = memo((props) => {
  useEffect(() => console.log(`Render Check3 id=${props.id}, lvl=${props.l}, a=${props.a}`));
  return (
      <div>
        <div>Check3m - {props.id} lvl:{props.l} a:{props.a}</div>
      </div>
  )  
} /*, (oldProps, newProps)=>{return true}*/);

const Check4 = memo((props) => {
  useEffect(() => console.log(`Render Check4 id=${props.id}, lvl=${props.l}`));
  return (
      <div>
        <div>Check4m - {props.id} lvl:{props.l} {props.children}</div>
      </div>
  )  
}, /*(oldProps, newProps)=>{return true}*/);


const Panel = ({id, color, children}) => {
  const [active, setActive] = useState(false);
  const [b, setB] = useState(0);
  
  useEffect(() => console.log('Render Panel-', id ));
  return (
      <div style={{border:"solid 2px", borderRadius:"0.5em", backgroundColor:color??"beige", padding:"0.5em", margin: "0.3em", minHeight:"4em"}}>
        <h3>Panel - {id}</h3>
        <button onClick={(e)=>{setActive(c=>!c);}}
          >{(active) ? 'Stop tracker':'Start tracker'}
        </button>  
        <button onClick={(e)=>{setB(a=>a+1)}}
          >{`b=${b}`}
        </button>  
        
        <div style={{backgroundColor:"#BBB8",}}>
          {children}
        </div>
      </div>
  )  
}

const SubPanel = ({id, color, children}) => {
  const [active, setActive] = useState(false);
  const [b, setB] = useState(0);
  
  useEffect(() => console.log('Render Panel-', id ));
  return (
      <div style={{border:"solid 2px", borderRadius:"0.5em", backgroundColor:color??"beige", padding:"0.5em", margin: "0.3em", minHeight:"4em"}}>
        <h3>Panel - {id}</h3>
        <button onClick={(e)=>{setActive(c=>!c);}}
          >{(active) ? 'Stop tracker':'Start tracker'}
        </button>  
        <button onClick={(e)=>{setB(a=>a+1)}}
          >{`b=${b}`}
        </button>  
        
        <div style={{backgroundColor:"#BBB8",}}>
          {children}
        </div>
      </div>
  )  
}


const RenderTestPg = (props) => {
  const [active, setActive] = useState(false);
  const [a, setA] = useState(0);
  useEffect(() => console.log(`Render TestPg active=${active}, a=${a}`));
  
  return (
    <div className="App">
      <article>
        <Logo2 title="University of North Texas" />
        <button onClick={(e)=>{setActive(c=>!c);}}
          >{(active) ? 'Stop tracker':'Start tracker'}
        </button>  
        <button onClick={(e)=>{setA(a=>a+1)}}
          >{`a=${a}`}
        </button>  
        <Check1 id="From main page C1" l='0'/>
        <Check2 id="From main page C2" l='0'/>
        <Check3 id="From main page C3" l='0' a={a}/>
        <Check4 id="From main page C4" l='0'> a={a}</Check4>
        <Panel id='1'>
          <Check1 id="Panel-1 C1" l='1'/>
          <Check2 id="Panel-1 C2" l='1'/>
          <Check3 id="Panel-1 C3" l='1' a={a}/>
          <Check4 id="Panel-1 C4" l='1'> a={a}</Check4>
          <SubPanel id='1-2' color="#6AFA">
            <Check1 id="Panel-1-2 C1" l='2'/>
            <Check2 id="Panel-1-2 C2" l='2'/>
            <Check3 id="Panel-1-2 C3" l='2' a={a}/>
            <Check4 id="Panel-1-2 C4" l='2'> a={a}</Check4>
          </SubPanel>
        </Panel>
        <Panel id='2' color="#FC6">
          <Check1 id="Panel-2 C1" l='1'/>
          <Check2 id="Panel-2 C2" l='1'/>
          <Check3 id="Panel-2 C3" l='1' a={a}/>
          <Check4 id="Panel-2 C4" l='1'> a={a} </Check4>
          <SubPanel id='2-2' color="#6FAA">
            <Check1 id="Panel-2-2 C1" l='2'/>
            <Check2 id="Panel-2-2 C2" l='2'/>
            <Check3 id="Panel-2-2 C3" l='2' a={a}/>
            <Check4 id="Panel-2-2 C4" l='2'> Zero 21 </Check4>
          </SubPanel>
        
        </Panel>
      </article>
    </div>
  );
}

export default RenderTestPg;